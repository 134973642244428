import React, { useState, useEffect } from 'react';
import Select from "react-select";
import Categories from './Static/category.json';
import Brands from './Static/brand.json';

import {
	Alert,
	Badge,
	Button,
	Card,
	Form,
	InputGroup,
	Navbar,
	Nav,
	Container,
	Row,
	Col,
} from "react-bootstrap";

function AddItem({ cnor, firstName, lastName, email,
	setCategory, setCatLabel, setBrand, setBrandLabel,
	setPaymentMethod, setPayoutPercentage,
	handleProductTitle, handlePrice, handleProductNotes, productNotes,
	titleRequiredState, priceRequiredState,
	handleItemCreateClear, handleSubmitItem,
	setPaymentRequiredState, paymentError, setPaymentError,
	setPayoutRequiredState, setPayoutError, payoutError,
}) {

	//auto select payout % dropdown 
	const [selected, setSelected] = React.useState("");

	const changeSelectOptionHandler = (event) => {
		setSelected(event);
	};

	if (selected === "in-store") {
		setPayoutPercentage(75)
	} else if (selected === "full-bike") {
		setPayoutPercentage(75)
	} else if (selected === "full-bike-store-credit") {
		setPayoutPercentage(75)
	} else if (selected === "tent-sale") {
		setPayoutPercentage(75)
	} else if (selected === "Venmo") {
		setPayoutPercentage(65)
	} else if (selected === "rep-venmo") {
		setPayoutPercentage(75)
	} else if (selected === "employee-venmo") {
		setPayoutPercentage(90)
	} else if (selected === "employee-store-credit") {
		setPayoutPercentage(90)
	} else if (selected === "full-payout") {
		setPayoutPercentage(100)
	}
	//auto select payout % dropdown 

	//fix for when a user creates product and keeps creating products when we dont want
	// to clear the dropdown options for multi product creation process
	useEffect(() => {
		setPaymentMethod("")
		setPaymentRequiredState(null)
		setPayoutPercentage("")
		setPayoutRequiredState(null)
	}, []);
	return (
		<>
			<Container fluid>
				<div className="section-image" >
					<Container>
						<Row>
							<Col md="12" sm="6">
								<Form action="" id="add-item-form" className="form" method="">
									<Card>
										<Card.Header>
											<Card.Header>
												<Card.Title as="h4">Add Item(s) To Consignor ID {cnor} - <span className="text-success font-weight-bold">{firstName} {lastName}</span> - <span className="text-info font-weight-bold">{email}</span> </Card.Title>
											</Card.Header>
										</Card.Header>
										<Card.Body>
											<Row>
												<Col className="pr-1" md="6">
													<Form.Group
														className={
															titleRequiredState ? "has-success" : "has-error"
														}
													>
														<label>Product Title</label>
														<Form.Control
															onChange={handleProductTitle}
															type="text"
														></Form.Control>
														{titleRequiredState ? null : (
															<label className="error">
																Product Title Requred
															</label>
														)}
													</Form.Group>
												</Col>
												<Col className="pl-3" md="6">
													<Form.Group
														className={
															priceRequiredState ? "has-success" : "has-error"
														}
													>
														<label>Price</label>
														<Form.Control
															onChange={handlePrice}
															type="text"
														>
														</Form.Control>
														{priceRequiredState ? null : (
															<label className="error">
																Price Required, must be a number
															</label>
														)}
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col className="pr-0 mr-0" md="6">
													<Form.Group>
														<label>Category</label>
														<Select
															className="react-select primary"
															classNamePrefix="react-select"
															name="Category"
															onChange={(value) => {
																setCategory(value.value)
																setCatLabel(value.label)
															}}
															options={Categories}
															placeholder="Category"
														/>
													</Form.Group>
												</Col>
												<Col className="" md="6">
													<Form.Group>
														<label>Brand</label>
														<Select
															className="react-select primary"
															classNamePrefix="react-select"
															name="Brand"
															onChange={(value) => {
																setBrand(value.value)
																setBrandLabel(value.label)
															}}
															options={Brands}
															placeholder="Brand"
														/>
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col md="12">
													<Form.Group>
														<label>Payment Method</label>
														<Select
															className="react-select primary"
															classNamePrefix="react-select"
															name="Payment Option"
															onChange={(value) => {
																setPaymentMethod(value.value),
																	setPaymentRequiredState(value.value),
																	setPaymentError(null)
																changeSelectOptionHandler(value.value)
															}}
															options={[
																{ value: "in-store", label: "In-Store Credit - 75%" },
																// { value: "paypal", label: "PayPal - 65%" },
																{ value: "Venmo", label: "Venmo - 65%" },
																{ value: "rep-venmo", label: "Rep-Venmo - 75%" },
																{ value: "full-bike", label: "Full Bike-Venmo - 75% - L9 Cap $350" },
																{ value: "full-bike-store-credit", label: "Full Bike-Venmo (store credit) - 75% - L9 Cap $350" },
																{ value: "employee-venmo", label: "Employee-Venmo - 90%" },
																{ value: "employee-store-credit", label: "Employee-Store credit - 90%" },
																{ value: "tent-sale", label: "Tent Sale - 75% - L9 Cap $250" },
																{ value: "full-payout", label: "Full-Payout - 100%" },
															]}
															placeholder="Payout Options"
														/>
														{paymentError}
													</Form.Group>
												</Col>
												{/* <Col className="" md="6">
                                                    <Form.Group>
                                                        <label>Payout %</label>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="Payout Method"
                                                            // value={payoutPercentage}
                                                            onChange={(value) => {
                                                                setPayoutPercentage(value.value),
                                                                    setPayoutRequiredState(value.value),
                                                                    setPayoutError(null)
                                                            }}
                                                            options={[
                                                                { value: 65, label: "65" },
                                                                { value: 75, label: "75" },
                                                                { value: 90, label: "90" },
                                                            ]}
                                                            placeholder="Select Rate"
                                                        />
                                                        {payoutError}
                                                    </Form.Group>
                                                </Col> */}
											</Row>
											<Row>
												<Col md="12">
													<Form.Group>
														<label>Initials</label>
														<Form.Control
															cols="80"
															onChange={handleProductNotes}
															defaultValue={productNotes}
															type="text"
															rows="4"
														></Form.Control>
													</Form.Group>
												</Col>
											</Row>
											<Button
												className="btn-fill pull-right"
												type="submit"
												variant="success"
												onClick={handleSubmitItem}
											>
												Add Item (Close)
											</Button>
											<Button
												className="btn-fill pull-right ml-4"
												type="submit"
												variant="info"
												onClick={handleItemCreateClear}
											>
												Add Item (Keep Adding)
											</Button>
											<div className="clearfix"></div>
										</Card.Body>
									</Card>
								</Form>
							</Col>
						</Row>
					</Container>
				</div>
			</Container>
		</>
	);
}

export default AddItem;
