import React, { useState } from "react";
import axios from "axios";
// const fetch = require("node-fetch");


import { Container, Button } from "react-bootstrap";


const Photos = ({ page = 'Photos' }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [code, setCode] = useState(null);
  const [json, setJson] = useState(null);
  const [df, setDf] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [postError, setPostError] = useState(null);
  const [folderBool, setFolderBool] = useState(false);
  const [folder, setFolder] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteUrl, setDeleteUrl] = useState(null);
  const [errorDict, setErrorDict] = useState(null);
  const [downloadWithErrors, setDownloadWithErrors] = useState(false);
  const [caUpload, setCaUpload] = useState(null)
  const [caErrors, setCaErrors] = useState(null)
  const handleCheckboxChange = (event) => {
    setFolderBool(event.target.checked);
  };

  const handleErrorChange = (event) => {
    setDownloadWithErrors(event.target.checked);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async e => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      if (folderBool) {
        folder.forEach((file) => formData.append("file[]", file));
      } else {
        formData.append("file[]", "null");
      }

      // Make the POST request
      //local server
      // let url = (page === 'Photos') ? "http://127.0.0.1:5000/ImageCsv" : "http://127.0.0.1:5000/filePackageBuilder";
      //deployed server
      let url = (page === 'Photos') ? "https://l9golf.com/ImageCsv" : "https://l9golf.com/filePackageBuilder";

      const response = await axios.post(
        url,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (response.status === 200) setCode(200);

      // console.log(response.data);
      setJson(response.data.df);
      setDf(response.data.df);
      setErrorDict(response.data.errorDict);
      console.log(response.data.errorDict);
      setPostError(null);
    } catch (error) {
      // Handle errors here
      setPostError(error.message);
      console.error(error.message);
      console.log(error.response.data);
      if (error.response.data) {
        alert(
          "Error: " +
          error.response.data +
          "\n\nPlease verify the excel sheet and contact the IT department if unresolved."
        );
      } else {
        alert(
          "There was an error uploading your file. Please verify your file. \n\nIf the problem persists, please contact the IT department."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deleteImage = async (key) => {
    try {
      setDeleteLoading(true);
      console.log("delete image with key:", key);
      setDeleteUrl(key);
      const formData = new FormData();
      formData.append("url", key);
      formData.append("df", json);
      const response = await axios.post(
        "https://l9golf.com/DeleteImage",
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data);
      setJson(response.data);
      setDf(response.data);
      // You can implement your delete image logic here
    } catch (error) {
      console.error(error.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  const renderImages = (images) => {
    const imageKeys = Object.keys(images).filter(
      (key) => key.startsWith("Server Image") && images[key]
    );

    return (
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
        {imageKeys.map((key) => {
          const imageUrl = images[key];
          const isDeleting = deleteUrl === imageUrl && deleteLoading;

          return (
            <div key={imageUrl} className="col mb-3">
              <div className="text-center">
                <p>Image {key.slice(-1)}</p>
                <img
                  style={{ maxWidth: "100%", height: "auto" }}
                  src={imageUrl}
                  alt={key}
                  className="border"
                />
                {isDeleting && (
                  <div className="col-auto">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                )}
                <button
                  className="btn btn-danger mt-2"
                  onClick={() => deleteImage(imageUrl)}>
                  Delete
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const downloadCSV = async () => {
    try {
      const formData = new FormData();
      formData.append("df", df);
      if (errorDict) {
        formData.append("downloadWithErrors", downloadWithErrors);
      }
      else {
        formData.append("downloadWithErrors", true);
      }
      formData.append("errorDict", JSON.stringify(errorDict));
      if (folderBool) {
        formData.append("bool", "true");
      } else {
        formData.append("bool", "false");
      }

      // Make the GET request to download the file
      const downloadResponse = await axios.post(
        // "http://127.0.0.1:5000/downloadTest",
        "https://l9golf.com/downloadTest",
        formData,
        { responseType: "blob", withCredentials: true }
      );
      if (downloadResponse.status !== 200) {
        throw new Error("Error downloading file");
      }

      const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "session.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      setPostError(null);
      console.log(downloadResponse);
    } catch (error) {
      // Handle errors here
      setPostError(error.message);
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpload = async () => {
    try {
      setJson("")
      setIsLoading(true)
      const formData = new FormData();
      formData.append("df", df);
      formData.append("clientUrl", "csvUpload")
      if (errorDict) {
        formData.append("downloadWithErrors", downloadWithErrors);
      }
      else {
        formData.append("downloadWithErrors", true);
      }
      formData.append("errorDict", JSON.stringify(errorDict));
      if (folderBool) {
        formData.append("bool", "true");
      } else {
        formData.append("bool", "false");
      }

      // Make the GET request to download the file
      const uploadResponse = await axios.post(
        "https://l9golf.com/CaUpload",
        formData,
        { withCredentials: true }
      );

      if (uploadResponse.data.errors.length == 0) {
        setCaUpload(true)
      }
      else {
        var errorJson = uploadResponse.data.errors
        setCaErrors(
          errorJson
            .map(error => {
              // Format each error message to include both SKU and imgNum
              return `SKU: ${error.SKU || 'N/A'}, Image Number: ${error.imgNum || 'N/A'}`;
            })
            .join("; ") // Join error messages with a semicolon and space for readability
        );
      }
      setPostError(null);
      console.log(uploadResponse);
    } catch (error) {
      // Handle errors here
      setPostError(error.message);
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <div>
        <div className="container mt-5">
          <h2>Select an Image Sourcing CSV</h2>

          <input
            className="form-control"
            type="file"
            accept=".csv"
            onChange={handleFileChange}

          />

          <div className="row g-3 mt-1">
            <div className="col-auto">
              <label className="form-check-label">
                Do you have a folder with product images?
              </label>
            </div>
            <div className="col-auto">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
          {folderBool && (
            <div className="row g-3 mt-1">
              <div className="col-auto">
                <label className="form-label">
                  Select a folder with product images.
                </label>
              </div>
              <div className="col-auto">
                <input
                  required
                  type="file"
                  webkitdirectory="true"
                  directory=""
                  className="form-control"
                  onChange={(event) => {
                    const files = [];
                    const fileList = event.dataTransfer
                      ? event.dataTransfer.files
                      : event.target.files;
                    for (let i = 0; i < fileList.length; i++) {
                      files.push(fileList[i]);
                    }

                    setFolder(files);
                  }}
                />
              </div>
            </div>
          )}
          {selectedFile && (
            <div className="row g-3 mt-1">
              <div className="col-auto">
                <Button
                  // className="btn btn-block btn-large"
                  onClick={handleSubmit}
                  disabled={!selectedFile}
                >
                  Upload
                </Button>
              </div>
            </div>

          )}



          {code === 200 && (
            <div className="row g-3 mt-1">
              <div className="col-auto">
                <button
                  className="btn btn-primary btn-block btn-large mt-1 mb-2"
                  onClick={downloadCSV}>
                  Download CSV
                </button>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-primary btn-block btn-large mt-1 mb-2"
                  onClick={handleUpload}>
                  Upload to Rithum
                </button>
              </div>
            </div>

          )}
          {caUpload && (
            <div className="row g-3 mt-1">
              <div className="col-auto">
                <p>Images were successfully uploaded to Rithum</p>
              </div>
            </div>
          )}
          {caErrors && (
            <div className="row g-3 mt-1">
              <div className="col-auto">
                <p>You can still download the CSV and upload manually.</p>
                <p>However, the following images were not uploaded successfully: {caErrors}</p>
              </div>
            </div>
          )}


          {isLoading && (
            <div className="col-auto mt-1">
              <div className="spinner-border" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          )}

          {errorDict && (
            <div>
              <div className="row g-3">
                <div className="col-auto">
                  <label className="form-check-label">
                    Download the CSV with the errors?
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={handleErrorChange}
                  />
                </div>
              </div>
              <h2>Errors</h2>
              <p>These images were not uploaded successfully. </p>
              <ul>
                {Object.keys(errorDict).map((key) => (
                  <li key={key}>
                    {key}: {errorDict[key]}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {json && (
            <div>
              {Object.entries(JSON.parse(json)).map(([sku, skuData]) => (
                <div key={sku} className="card mb-3">
                  <div className="card-body">
                    <h2 className="card-title">{skuData.TITLE}</h2>
                    <p className="card-title">SKU: {sku}</p>
                    {renderImages(skuData)}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {json === null && (
        <div>
          <h3>Guide</h3>
          <div className="row">
            <div className="col-md-6">
              <ol type="1">
                {page === 'Photos' && (
                  <li><p>Source images using the <a style={{ textDecoration: 'underline', color: 'blue' }} href="https://docs.google.com/spreadsheets/d/1Z3qDHYXc70ufvzhGgKAwzF01A_5ehlKZDuh-8_2-0NI/edit#gid=275974574">listing template.</a></p></li>
                )}
                {page === 'Packages' && (
                  <li><p>Source images using the <a style={{ textDecoration: 'underline', color: 'blue' }} href="https://docs.google.com/spreadsheets/d/1TPrpsQmjG-RliSZbv7BH7TblVpMWqbYd2Emxty_INvA/edit#gid=1781918333"> package listing template</a>.
                    View the single package page to see what images are accepted.</p></li>
                )}
                <ul>
                  <li><p className="font-weight-bold">Sourcing URLs</p>
                    <p className="mt-0">To source image URLs navigate to the product page, right click the image and select "Copy Image Address". Paste this URL into the corresponding "Image #" column on the sheet.</p></li>
                  <li><p className="font-weight-bold">Vendor Images</p>
                    <p>If you can't find the image on the internet then check salesforce or ask around the office for vendor media sites. Once the images have been downloaded you can put the filename in the "Image #" column.</p></li>
                  <h6>Windows:</h6>
                  <p>To copy the file name select the file and hit F2 (or right click and click rename). Then Ctrl+C to copy.</p>
                  <h6>Mac:</h6>
                  <p>To copy the file name select the file and hit Enter (or right click and click rename). Then Command+C to copy.</p>
                  <li><p className="font-weight-bold">Videos</p>
                    <p>If you find a product video take everything after the '=' in the URL and paste it in the "Video" column. EX: if the URL is https://www.youtube.com/watch?v=EojpXV8yRRM you would put EojpXV8yRRM in the "Video" column.</p></li>
                </ul>
                <li className="mt-3"><p>Download the image sourcing page as a CSV.</p></li>
                <ul>
                  <li><p>If you downloaded images to your computer organize them into a single folder with no sub folders. (to speed up the upload process please try to only include images used on the sheet in the folder.)</p></li>
                </ul>
              </ol>
            </div>
            {page === 'Photos' && (
              <div className="col-md-6">
                <img className="img-fluid" src="https://bikewagonmedia.com/media/L9/Guide/Screenshot%202023-09-26%20at%2011.16.53%20AM.png" alt="listing template" />
              </div>
            )}
            {page === 'Packages' && (
              <div className="col-md-6">
                <img className="img-fluid" src="https://bikewagonmedia.com/media/L9/Guide/Screenshot%202023-10-12%20at%202.11.36%20PM.png" alt="package listing template" />
              </div>
            )}
          </div>
        </div>
      )
      }

    </Container>
  );
};

export default Photos;
