import React, { useState } from 'react';
import ReactTable from "./ReactTable/ReactTable";
import EditConsignor from './EditConsignor';
import AddItems from './AddItems';
import Items from './Items';
import axios from 'axios';
import NotificationAlert from "react-notification-alert";
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../../store/reducer';
import { useSelector } from 'react-redux';

import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Modal,
} from "react-bootstrap";


const moment = require('moment');
const createdDate = moment().format('MMMM Do YYYY, h:mm:ss a');

function ConsignorTable(props) {
    const { resultsTable, defaultTableUpdated, fnReqSearchQueryState, search } = props;
    const username = useSelector((reduxState) => reduxState.username);
    const notificationAlertRef = React.useRef(null);
    const notify = (place, type, msg) => {
        var type = type
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    //edit consignor state
    const [modal, setModal] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);
    const [modalProduct, setModalProduct] = useState(false);
    const [cnor, setCnor] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [phoneAllowText, setPhoneAllowText] = useState(false);
    const handleAllowText = (e) => {
        e.target.checked === true ? setPhoneAllowText(true) : setPhoneAllowText(false);
    }
    const [cnorNotes, setCnortNotes] = useState("");

    // Validators for Add Item component 
    const isRequired = (value) => value !== null && value !== "" && value;
    const isNumber = (value) => !isNaN(value) && value !== "";

    //add item + validator state
    const [productTitle, setProductTitle] = useState("");
    const [titleRequiredState, setTitleRequiredState] = useState(true);
    const [price, setPrice] = useState("");
    const [priceRequiredState, setPriceRequiredState] = useState(true);
    const [productNotes, setProductNotes] = useState(`Created by:${username} Date:${createdDate}`);

    // 


    //select options WIHTOUT validators 
    const [category, setCategory] = useState("");
    const [catLabel, setCatLabel] = useState("");
    const [brand, setBrand] = useState("");
    const [brandLabel, setBrandLabel] = useState("");

    //select option validators
    const [paymentMethod, setPaymentMethod] = useState("");
    const [paymentRequiredState, setPaymentRequiredState] = useState(null);
    const [paymentError, setPaymentError] = useState(null);

    const [payoutPercentage, setPayoutPercentage] = useState("");
    const [payoutRequiredState, setPayoutRequiredState] = useState(null);
    const [payoutError, setPayoutError] = useState(null);

    const itemStatus = 'Ready For Sale';
    // const [itemStatusRequiredState, setItemStatusRequiredState] = useState(null);
    // const [statusError, setStatusError] = useState(null);

    const validatedPayment = () => {
        paymentRequiredState === null
            ? setPaymentError(
                <small className="text-danger">You must select a payment option.</small>
            )
            : setPaymentError(null);
        var pr = paymentRequiredState !== null;
        var valid = pr;
        return valid
    }
    //removed because payout option is now auto caculated - NM
    // const validatedPayout = () => {
    //     payoutRequiredState === null
    //         ? setPayoutError(
    //             <small className="text-danger">You must select a payout option.</small>
    //         )
    //         : setPayoutError(null);
    //     var po = payoutRequiredState !== null;
    //     var valid = po;
    //     return valid
    // }

    //edit consignor handlers
    const handleFirstName = e => setFirstName(e.target.value);
    const handleLastName = e => setLastName(e.target.value);
    const handleEmail = e => setEmail(e.target.value);
    const handlePhone = e => setPhone(e.target.value);
    const handleAddress = e => setAddress(e.target.value);
    const handleCity = e => setCity(e.target.value);
    const handleState = e => setState(e.target.value);
    const handleZip = e => setZip(e.target.value);
    const handleCnortNotes = e => setCnortNotes(e.target.value);

    //add item handlers
    const handleProductTitle = (e) => {
        setProductTitle(e.target.value)
        isRequired(e.target.value) ? setTitleRequiredState(true) : setTitleRequiredState(false);
    }
    const handlePrice = (e) => {
        setPrice(e.target.value)
        isNumber(e.target.value) ? setPriceRequiredState(true) : setPriceRequiredState(false);
    }
    const handleProductNotes = e => setProductNotes(e.target.value);

    // Edit consignor     
    const handleSubmit = e => {
        e.preventDefault();
        props.showLoader()
        axios.put('/cnor/update', { cnor, firstName, lastName, email, phone, address, city, state, zip, cnorNotes, phoneAllowText })
            .then((response) => {
                props.hideLoader()
                setModal(!modal)
                notify("tc", "info", "Consignor Updated!")
                //rerender page based on default or search
                if (fnReqSearchQueryState === true) {
                    defaultTableUpdated();
                } else {
                    search();
                }
            }).catch(err => {
                // setErrorState(false)
                props.hideLoader()
                console.log((err))
            })
    }

    // Create Items
    const handleSubmitItem = e => {
        e.preventDefault();
        validatedPayment();
        // validatedPayout(); 
        // validatedStatus();
        let formNotFilled = 0
        if (!titleRequiredState || !isRequired(productTitle)) {
            setTitleRequiredState(false);
            formNotFilled++
        }
        if (!priceRequiredState || !isRequired(price)) {
            setPriceRequiredState(false);
            formNotFilled++
        }
        if (paymentRequiredState === null) {
            formNotFilled++
        }
        // if (payoutRequiredState === null) {
        //     formNotFilled++
        // }
        if (formNotFilled > 0) {
            return
        }
        else {
            setTitleRequiredState(true);
            setPriceRequiredState(true);
        }
        const cost = price * (payoutPercentage / 100).toFixed(2);
        props.showLoader()
        axios.post('/cnor/item', {
            cnor, productTitle, price, paymentMethod, payoutPercentage, itemStatus, productNotes,
            firstName, lastName, email, phone, address, city, state, zip, username, category, brand, catLabel, brandLabel,
            cost
        })
            .then(() => {
                props.hideLoader()
                notify("tc", "success", "Item Created!")
                setModalAdd(!modalAdd)
                //rerender page based on default or if user searched 
                if (fnReqSearchQueryState === true) {
                    defaultTableUpdated();
                } else {
                    search();
                }
                setProductTitle("")
                setTitleRequiredState(true)
                setPrice("")
                setPriceRequiredState(true)
                setProductNotes("")
                setCategory("")
                setBrand("")
                setPaymentMethod("")
                setPaymentRequiredState(null)
                setPaymentError(null)
                setPayoutPercentage("")
                // setPayoutRequiredState(null)
                setPayoutError(null)
            }).catch(err => {
                props.hideLoader()
                notify("tc", "danger", "Error attempting to create item, please try again. ")
                console.log(err)
            })
    }

    const clearForm = () => {
        document.getElementById("add-item-form").reset();
    }

    // Create Item Continue & Clear 
    const handleItemCreateClear = e => {
        validatedPayment();
        // validatedPayout(); 
        e.preventDefault();
        let formNotFilled = 0
        if (!titleRequiredState || !isRequired(productTitle)) {
            setTitleRequiredState(false);
            formNotFilled++
        }
        if (!priceRequiredState || !isRequired(price)) {
            setPriceRequiredState(false);
            formNotFilled++
        }
        if (paymentRequiredState === null) {
            formNotFilled++
        }
        // if (payoutRequiredState === null) {
        //     formNotFilled++
        // }
        if (formNotFilled > 0) {
            return
        }
        else {
            setTitleRequiredState(true);
            setPriceRequiredState(true);
        }
        props.showLoader()
        axios.post('/cnor/item', {
            cnor, productTitle, price, paymentMethod, payoutPercentage, itemStatus, productNotes,
            firstName, lastName, email, phone, address, city, state, zip, username, category, brand, catLabel, brandLabel
        })
            .then(() => {
                props.hideLoader()
                notify("tc", "success", "Item Created keep going!")
                clearForm()
                if (fnReqSearchQueryState === true) {
                    defaultTableUpdated();
                } else {
                    search();
                }
                setProductTitle("")
                setTitleRequiredState(true)
                setPrice("")
                setPriceRequiredState(true)
                setProductNotes("")
                setPaymentError(null)
                setPayoutError(null)
            }).catch(err => {
                props.hideLoader()
                console.log(err)
            })
    }

    const render = resultsTable.map((prop, key) => {
        return {
            id: key,
            cnor: prop.id,
            first_name: prop.first_name,
            last_name: prop.last_name,
            email: prop.email,
            phone: prop.phone,
            address: prop.address,
            city: prop.city,
            state: prop.state,
            zip: prop.zip,
            count: prop.count,
            allow_txt: prop.allow_txt,
            actions: (

                <div className="actions-center">
                    <Button
                        onClick={() => {
                            let obj = render.find((o) => o.id === key);
                            setModalAdd(!modal)
                            setCnor(obj.cnor)
                            setFirstName(obj.first_name)
                            setLastName(obj.last_name)
                            setEmail(obj.email)
                            setPhone(obj.phone)
                            setAddress(obj.address)
                            setCity(obj.city)
                            setState(obj.state)
                            setZip(obj.zip)
                        }}
                        variant="info"
                        size="med"
                        className="text-success btn-link"
                    >
                        <i className="fas fa-plus-square pr-0" />
                    </Button>

                    <Button
                        onClick={() => {
                            let obj = render.find((o) => o.id === key);
                            setModalProduct(!modalProduct)
                            setCnor(obj.cnor)
                            setFirstName(obj.first_name)
                            setLastName(obj.last_name)
                            setEmail(obj.email)
                            setPhone(obj.phone)
                            setAddress(obj.address)
                            setCity(obj.city)
                            setState(obj.state)
                            setZip(obj.zip)

                        }}
                        variant="info"
                        size="med"
                        className="text-info btn-link"
                    >
                        <i className="fas fa-tags mr-1" />
                        {prop.count}
                    </Button>
                    <Button
                        onClick={() => {
                            let obj = render.find((o) => o.id === key);
                            setModal(!modalAdd)
                            setCnor(obj.cnor)
                            setFirstName(obj.first_name)
                            setLastName(obj.last_name)
                            setEmail(obj.email)
                            setPhone(obj.phone)
                            setAddress(obj.address)
                            setCity(obj.city)
                            setState(obj.state)
                            setZip(obj.zip)
                            setPhoneAllowText(obj.allow_txt)
                        }}
                        variant="dark"
                        size="med"
                        className="text-warning btn-link"
                    >
                        <i className="text-dark fa fa-user" />
                    </Button>
                </div>
            ),
        };
    })
    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Body>
                                <ReactTable
                                    data={render}
                                    columns={[
                                        {
                                            Header: "First Name",
                                            accessor: "first_name",
                                            width: 90
                                        },
                                        {
                                            Header: "Last Name",
                                            accessor: "last_name",
                                        },
                                        {
                                            Header: "Email",
                                            accessor: "email",
                                        },
                                        {
                                            Header: "Phone",
                                            accessor: "phone",
                                        },
                                        {
                                            Header: "Address",
                                            accessor: "address",
                                        },
                                        {
                                            Header: "Actions",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                        },
                                    ]}
                                    className="-striped -highlight primary-pagination ml-0 mr-0"
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    dialogClassName="my-modal"
                    className="modal modal-primary"
                    onHide={() => setModal(!modal)}
                    show={modal}
                >
                    <Modal.Header className="justify-content-center mb-3"></Modal.Header>
                    <Modal.Body className="text-center mt-0 mb-0 pb-0 pt-0 mr-0 pr-0 ml-0 pl-0">
                        <EditConsignor
                            cnor={cnor}
                            firstName={firstName}
                            lastName={lastName}
                            email={email}
                            phone={phone}
                            address={address}
                            city={city}
                            state={state}
                            zip={zip}
                            cnorNotes={cnorNotes}
                            handleFirstName={handleFirstName}
                            handleLastName={handleLastName}
                            handleEmail={handleEmail}
                            handlePhone={handlePhone}
                            handleAddress={handleAddress}
                            handleCity={handleCity}
                            handleState={handleState}
                            handleZip={handleZip}
                            handleCnortNotes={handleCnortNotes}
                            handleSubmit={handleSubmit}
                            setModal={setModal}
                            handleAllowText={handleAllowText}
                            phoneAllowText={phoneAllowText}
                            fnReqSearchQueryState={fnReqSearchQueryState}
                            defaultTableUpdated={defaultTableUpdated}
                            search={search}
                        />
                    </Modal.Body>
                    <div className="modal-footer ml-auto">
                        <Button
                            className="btn-simple"
                            onClick={() => setModal(!modal)}
                            variant="link"
                        >
                            Close
                        </Button>
                    </div>
                </Modal>
                <Modal
                    dialogClassName="my-modal"
                    className="modal modal-primary"

                    onHide={() => setModalAdd(!modalAdd)}
                    show={modalAdd}
                >
                    <Modal.Header className="justify-content-center mb-3"></Modal.Header>
                    <Modal.Body className="text-center mt-0 mb-0 pb-0 pt-0 mr-0 pr-0 ml-0 pl-0">
                        <AddItems
                            cnor={cnor}
                            firstName={firstName}
                            lastName={lastName}
                            email={email}

                            paymentMethod={paymentMethod}
                            payoutPercentage={payoutPercentage}

                            setCategory={setCategory}
                            setCatLabel={setCatLabel}

                            setBrand={setBrand}
                            setBrandLabel={setBrandLabel}

                            setPaymentMethod={setPaymentMethod}
                            setPayoutPercentage={setPayoutPercentage}

                            setPaymentRequiredState={setPaymentRequiredState}
                            paymentError={paymentError}
                            setPaymentError={setPaymentError}

                            setPayoutRequiredState={setPayoutRequiredState}
                            setPayoutError={setPayoutError}
                            payoutError={payoutError}

                            handleProductTitle={handleProductTitle}
                            handlePrice={handlePrice}
                            handleProductNotes={handleProductNotes}
                            productNotes={productNotes}

                            titleRequiredState={titleRequiredState}
                            priceRequiredState={priceRequiredState}

                            handleSubmitItem={handleSubmitItem}
                            handleItemCreateClear={handleItemCreateClear}
                        />
                    </Modal.Body>
                    <div className="modal-footer ml-auto">
                        <Button
                            className="btn-simple"
                            onClick={() => setModalAdd(!modalAdd)}
                            variant="link"
                        >
                            Close
                        </Button>
                    </div>
                </Modal>
                <Modal
                    dialogClassName="my-modal-items"
                    className="modal modal-primary"

                    onHide={() => setModalProduct(!modalProduct)}
                    show={modalProduct}
                >
                    <Modal.Header className="justify-content-center mb-3"></Modal.Header>
                    <Modal.Body className="text-center mt-0 mb-0 pb-0 pt-0 mr-0 pr-0 ml-0 pl-0">
                        <Items
                            cnor={cnor}
                            firstName={firstName}
                            lastName={lastName}
                            email={email}
                            phone={phone}
                            address={address}
                            city={city}
                            state={state}
                            zip={zip}
                            fnReqSearchQueryState={fnReqSearchQueryState}
                            defaultTableUpdated={defaultTableUpdated}
                            search={search}
                        />
                    </Modal.Body>
                    <div className="modal-footer ml-auto">
                        <Button
                            className="btn-simple"
                            onClick={() => setModalProduct(!modalProduct)}
                            variant="link"
                        >
                            Close
                        </Button>
                    </div>
                </Modal>
            </Container>
        </>
    );
}

const mapDispatchToProps = {
    showLoader,
    hideLoader
}

export default connect(undefined, mapDispatchToProps)(ConsignorTable);